import { Col, Container, Nav, NavItem, NavLink, Navbar, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const MobileHeader = () => {
  const location = useLocation();
  const imageUrl = `${process.env.PUBLIC_URL}/images/mobile/header_background.png`;
  const logoImg = `${process.env.PUBLIC_URL}/images/logo.svg`;
  const menuIcon = `${process.env.PUBLIC_URL}/images/mobile/Icon.svg`;
  const mobileImage = `${process.env.PUBLIC_URL}/images/mobile/mobile-home.png`;
  const googlePlayImage = `${process.env.PUBLIC_URL}/images/google-play.png`;
  const playStoreImage = `${process.env.PUBLIC_URL}/images/play-store.png`;
  const [isOpen, setIsOpen] = useState(false);
  const [isHomePage, setIsHomePage] = useState(location.pathname === '/');
  const [isFeaturePage, setIsFeaturePage] = useState(location.pathname.includes('/features'));
  const [isAboutPage,  setIsAboutPage] = useState(location.pathname.includes('/about-us'));
  const [isHelpCenterPage,  setIsHelpCenterPage] = useState(location.pathname.includes('/help-center'));
  const [positions, setPosition] = useState(7.209); // Initial top margin


  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  
  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 0) {
        setPosition(0); 
      } else {
        setPosition(7.209);
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []); 

  useEffect(() => {
    const handleRouteChange = () => {
      setIsHomePage(location.pathname === '/');
      setIsFeaturePage(location.pathname.includes('/features'));
      setIsAboutPage(location.pathname.includes('/about-us'));
      setIsHelpCenterPage(location.pathname.includes('/help-center'));
      
    };

    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, [location]);

  return (
    <header className= {isHomePage ? "mobile-header" : "header"}>
      <Nav className="justify-content-end nav">
        <NavItem>
          <NavLink className="active2" to="#"><span> Personal Finance </span></NavLink>
        </NavItem>
        <NavItem className='business'>
          <NavLink href="https://business.mafhoom.app/"><span> Business </span></NavLink>
        </NavItem>
      </Nav>
      <div className='container' style={{position:'fixed', zIndex:3, maxWidth:  '100%',  top: `${positions}vw`}}>
        <a className='logo' href="/">
          <img src={logoImg} alt="mafhoom logo"></img>
        </a>
        <img className='menu-toggle' onClick={toggleMenu} src={menuIcon} alt="menuIcon"></img>

        <nav  className={isOpen ? 'menu open' : 'menu' }  >
          <ul>
            <li ><a href="/features" onClick={() => isFeaturePage ? "lined" : ''} >FEATURES</a></li>
            <li><a href="/about-us" className={isAboutPage ? "lined" : ''} >ABOUT</a></li>
            <li><a href="/news"  >NEWS</a></li>
            <li><a href="/help-center"  >HELP CENTER</a></li>
          </ul>
        </nav>
      </div>

      {isHomePage ?
        <Row >
          <Col md={12} style={{marginTop: '30px'}}>
            <img className="header-background" src={imageUrl} alt="mobile header"></img></Col>
          < Col xs={6} md={6} >
            <h2 className="home-header-head"> Transform Your Finances </h2>
            <p className="home-header-text">
            Gain clarity and take control of your financial journey. Track every aspect of your finances, make smarter decisions, save effectively, and prepare for the future with ease              </p>
            <div >
              <a href='https://play.google.com/store/apps/details?id=app.mafhoom.mafhoom&pli=1' className='google-play'><img src={googlePlayImage} alt="" ></img></a>
              <a href='https://apps.apple.com/ae/app/mafhoom-budget-bills-deals/id6443959633' className='play-store'><img src={playStoreImage} alt="" ></img></a>
            </div>
          </Col >
          <Col xs={6} md={6}>
            <img src={mobileImage} alt="mobileImage" className='header-img-mobile' ></img>
          </Col>
        </Row >
        : ``}
    </header>
  );
};

export default MobileHeader;