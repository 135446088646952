import React from 'react';
import { Col, Row } from "react-bootstrap";
import CONFIG from "../../config";



const FeatureSection = (featureIndex) => {

    const feature = CONFIG['FEATURES'][featureIndex.featureIndex];

    return (
        <div className="feature-section">
            <Row>
                <Col md={6} sm={6} className='w-50' style={{ height: 'fit-content' }} >
                    <h3> {feature.feature_title} </h3>
                    <div>

                        <p className="p-feature-section" dangerouslySetInnerHTML={{ __html: feature.feature_details.description }} >

                        </p>
                    </div>
                </Col>

                <Col md={6} sm={6} style = {{ height: CONFIG['isMobile'] ? 'fit-content' : '10px' }} >
                    <img src={CONFIG['isMobile'] ?feature.feature_details.mobile_image:feature.feature_details.image} alt="Feature"></img>
                </Col>
                <Col md={6} sm={6} className='w-50'>
                    <div>
                        <ul className="ul-feature-section">
                            {feature.feature_details.points.map((_, index) => (

                                <a className='d-flex text-decoration-none text-black'>  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0976 6.31658 12.0976 5.68342 11.7071 5.29289C11.3166 4.90237 10.6834 4.90237 10.2929 5.29289L7 8.58579L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z" fill="#54BA5E" />
                                </svg>
                                    <li key={index}> {_} </li> </a>
                            ))}
                        </ul>
                    </div>
                </Col>
            </Row>

        </div >

    );
};

export default FeatureSection;