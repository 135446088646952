import React, { useEffect, useState } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import '../../style/news.css';
import { format } from 'date-fns';
import { getNews } from '../pages/news';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const NewSection = () => {
    const searchIcon = `${process.env.PUBLIC_URL}/images/search-icon.png`;
    const linkedinImage = `${process.env.PUBLIC_URL}/images/linkedin.svg`;
    const facebookImage = `${process.env.PUBLIC_URL}/images/facebook.svg`;
    const instaImage = `${process.env.PUBLIC_URL}/images/insta-icon.svg`;
    const twitterImage = `${process.env.PUBLIC_URL}/images/twitter.svg`;
    const shareIcon = `${process.env.PUBLIC_URL}/images/share-icon.svg`;
    const viewIcon = `${process.env.PUBLIC_URL}/images/view-icon.svg`;
    const timeIcon = `${process.env.PUBLIC_URL}/images/time-icon.svg`;
    const hyphenIcon = `${process.env.PUBLIC_URL}/images/hyphen-icon.svg`;
    const [news, setNews] = useState([]);

    const [post, setPost] = useState(null);
    const { id } = useParams();
    useEffect(() => {
        getNew(id)
            .then(data => {
                setPost(data);
            })
            .catch(error => console.error("Error fetching news:", error));
    }, [id]);

    const DateComponent = ({ dateString }) => {
        const formattedDate = format(new Date(dateString), "do MMMM, yyyy");
        return <span className="post-date">{formattedDate}</span>;
    };

    getNews()
        .then(data => {
            setNews(data);

        })
        .catch(error => console.error("Error fetching news:", error));

    const renderTags = () => {

        return (post.tags.map((tag) => {
            return (
                <div className='tag'>
                    {tag}
                </div>
            )
        })

        );
    };


    const renderPost = () => {
        return (
            <>
                {post && (
                    <Row>
                        <Col md={7} sm={7} className='post'>
                            <h3>{post.title.rendered}</h3>
                            <span>By Mafhoom | <DateComponent dateString={post.date} /> | <img alt="shareIcon" src={shareIcon} /> 12K shares | <img alt="viewIcon" src={viewIcon} /> 1.2K views </span>
                            <LazyLoadImage src={(post.rttpg_featured_image_url.full[0]).replace("mafhoom.app", "news.mafhoom.app")} alt="New"  />
                            <div className="post-content" dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
                            {findNextPostId() && <Link to={`/news/${findNextPostId()}`} className='next-button'><button className='post button'> Next </button></Link>}

                            {findPrevPostId() && <Link to={`/news/${findPrevPostId()}`} className='prev-button'><button className='post button'> Previous </button></Link>}
                        </Col>
                        <Col md={4} sm={4} >
                            <div className='post-details-side'>
                                <div className="form-outline">
                                    <input type="search" id="form1" className="search-post form-control" placeholder='Search for article...' />
                                </div>
                                <button type="button" className="btn btn-primary search-icon d-flex">
                                    <img className="m-auto" src={searchIcon} alt="searchIcon" />
                                </button>
                            </div>
                            <div className='last-news-div'>
                                <h3 className='last-news'>Latest News</h3>
                                {renderItems()}


                            </div>
                            <div className='last-news-div'>
                                <h3 className='last-news'>Popular Tags</h3>
                                <div className='popular-tags'>
                                    {renderTags()}
                                </div>
                            </div>
                            <div className='last-news-div'>
                                <h3 className='last-news'>Share</h3>
                                <div className='popular-tags'>
                                    <img src={linkedinImage} className="social news-social" alt="linkedIn"></img>
                                    <img src={facebookImage} className="social news-social" alt="facebook"></img>
                                    <img src={twitterImage} className="social news-social" alt="twitter"></img>
                                    <img src={instaImage} className="social news-social" alt="twitter"></img>
                                </div>
                            </div>
                        </Col>

                    </Row>
                )}
            </>
        );
    };

    const renderItems = () => {
        return news.map((newsItem, index) => {
            return (
                <Link  to={{
                    pathname: `/news/${newsItem.id}`
                }} style={{textDecorationLine : 'none', color:'#000', fontSize:'0.9vw', fontWeight: 500}} ><div className='last-news-card'>
                        {newsItem.title.rendered}
                        <br></br>
                        <span> <DateComponent dateString={newsItem.date} />
                         <img src={hyphenIcon} alt='hyphenIcon'></img>
                          <img src={timeIcon} alt='timeIcon'></img>
                         2 min read </span>
                    </div></Link>
            );
        });
    };

    const findPrevPostId = () => {
        const currentIndex = news.findIndex(item => item.id === parseInt(post.id));
        if (currentIndex > 0) {
            return news[currentIndex - 1].id;
        } else {
            return null;
        }
    };

    const findNextPostId = () => {
        const currentIndex = news.findIndex(item => item.id === parseInt(post.id));
        if (currentIndex < news.length - 1) {
            return news[currentIndex + 1].id;
        } else {
            return null;
        }
    };

    return (
        <>
            {renderPost()}
        </>
    );
};

export default NewSection;

export const getNew = async (id) => {
    try {
        const response = await axios.get(`https://news.mafhoom.app/wp-json/wp/v2/posts/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching news:", error);
        throw error;
    }
};


