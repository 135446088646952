import React from 'react';
import CONFIG from '../../config';
import '../../style/feature.css';
import AboutUsSection from '../about-us/about-us-section';
 
const AboutUs = () => {
  const downloadAppImage = `${CONFIG['BASE_IMAGE_URL']}/about-us-download.png`;

  return (
    <div className='main-about-us m-auto'>
      <AboutUsSection image={downloadAppImage} />
    </div>
  );
};

export default AboutUs;
