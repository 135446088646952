import React from 'react';
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import CONFIG from "../../config";
import { useLocation } from 'react-router-dom';


const Popup = ({ isDropdownVisible }) => {
    const location = useLocation();
    const [isVisible, setIsVisible] = useState(isDropdownVisible);
    const pop_feature = CONFIG['FEATURES'];
    const [isHomePage, setIsHomePage] = useState(location.pathname === '/');


    return (
        <div className="popup">
            <div className="dropdown" style={{ display: isDropdownVisible || isVisible ? 'block' : 'none', marginTop: isHomePage ? 0 : '' }}
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}
            >
                <Row >
                    {pop_feature.map((_, index) => (
                        <Col key={index} xs={6} md={6} className="col-6-feedback">
                            <div className='d-flex'>
                                <img src={_.popup_feature_icon} alt='feature_icon'></img>
                                <h5 className='feedback-h5'>
                                    <a className='feedback-a' href={`/features/${_.feature_slug}`} >{_.feature_title}</a>
                                </h5>
                            </div>
                            <div><p className="p-feedback">{_.popup_feature_desc}</p></div>
                           
                        </Col>
                    ))}
                </Row >
            </div >

        </div>

    );
};
export default Popup