import React, { useState } from 'react';
import CONFIG from '../../config';
import { Col, Row } from 'react-bootstrap';

const SectionFive = () => {
  const financialImage = `${process.env.PUBLIC_URL}/images/financial-commitments.png`;
  const stayAheadImage = `${process.env.PUBLIC_URL}/images/stay-ahead-with-your-expenses.png`;
  const stayTopImage = `${process.env.PUBLIC_URL}/images/stay-on-top-of-your-financial-health.png`;
  const knowledgeImage = `${process.env.PUBLIC_URL}/images/knowledge-is-the-key-to-financial-empowerment.png`;
  const takeChargeImage = `${process.env.PUBLIC_URL}/images/take-charge.png`;
  const transactionImage = `${process.env.PUBLIC_URL}/images/transaction.png`;

  const financialHoverImage = `${process.env.PUBLIC_URL}/images/futcher_hover_1.png`;
  const stayAheadHoverImage = `${process.env.PUBLIC_URL}/images/futcher_hover_2.png`;
  const stayTopHoverImage = `${process.env.PUBLIC_URL}/images/futcher_hover_3.png`;
  const knowledgeHoverImage = `${process.env.PUBLIC_URL}/images/futcher_hover_4.png`;
  const takeChargeHoverImage = `${process.env.PUBLIC_URL}/images/futcher_hover_5.png`;
  const transactionHoverImage = `${process.env.PUBLIC_URL}/images/futcher_hover_6.png`;

  const financialBeforeImage = `${process.env.PUBLIC_URL}/images/financial-commitments-before.png`;
  const stayAheadBeforeImage = `${process.env.PUBLIC_URL}/images/stay-ahead-with-your-expenses-before.png`;
  const stayTopBeforeImage = `${process.env.PUBLIC_URL}/images/stay-on-top-of-your-financial-health-before.png`;
  const knowledgeBeforeImage = `${process.env.PUBLIC_URL}/images/knowledge-is-the-key-to-financial-empowerment-before.png`;
  const takeChargeBeforeImage = `${process.env.PUBLIC_URL}/images/take-charge-before.png`;
  const transactionBeforeImage = `${process.env.PUBLIC_URL}/images/transaction-before.png`;

  const financiaMobilelImage = `${process.env.PUBLIC_URL}/images/featcher-1.png`;
  const stayAheadMobileImage = `${process.env.PUBLIC_URL}/images/featcher-2.png`;
  const stayTopMobileImage = `${process.env.PUBLIC_URL}/images/featcher-3.png`;
  const knowledgeMobileImage = `${process.env.PUBLIC_URL}/images/featcher-4.png`;
  const takeChargeMobileImage = `${process.env.PUBLIC_URL}/images/featcher-5.png`;
  const transactionMobileImage = `${process.env.PUBLIC_URL}/images/featcher-6.png`;

  const features = [
    {
      'id': '1',
      'title': 'Stay ahead with your expenses',
      'desc': "Gain clarity and control over your finances with intuitive spending tracker. But that's just the beginning...",
      'src': stayAheadImage,
      'src_before' : stayAheadBeforeImage ,
      'a': "features/Track-Spending",
      'mobile': stayAheadMobileImage,
      'hover': financialHoverImage


    }, {
      'id': '2',
      'title': 'Stay on top of your financial health',
      'desc': 'Discover the power of financial well-being like never before...',
      'src':  stayTopImage,
      'src_before' : stayTopBeforeImage ,
      'a': "features/Financial-Health",
      'mobile': knowledgeMobileImage,
      'hover': stayAheadHoverImage


    }, {
      'id': '3',
      'title': 'Knowledge is the key to financial empowerment',
      'desc': 'Enhance your financial literacy by diving into curated educational content and embark on a journey of continuous learning and growth....',
      'src':knowledgeImage ,
      'src_before' : knowledgeBeforeImage ,
      'a': "features/Education",
      'mobile': takeChargeMobileImage,
      'hover': stayTopHoverImage


    }, {
      'id': '4',
      'title': 'Take charge of your finances',
      'desc': 'Create customizable budgets that adapt to your needs and lifestyle. Achieve your financial dreams one budget at a time...',
      'src': takeChargeImage ,
      'src_before' : takeChargeBeforeImage ,
      'a': "features/Budget",
      'mobile': financiaMobilelImage,
      'hover': knowledgeHoverImage


    }, {
      'id': '5',
      'title': 'Get in sync with your financial commitments',
      'desc': 'Never miss a bill again! Keep tabs on your due payments, stay on track and stress-free...',
      'src':  financialImage,
      'src_before' : financialBeforeImage ,
      'a': "features/Recurring",
      'mobile': stayTopMobileImage,
      'hover': takeChargeHoverImage


    }, {
      'id': '6',
      'title': 'Every transaction tells a story',
      'desc': 'We help you decode the narrative, uncover insights, and make informed financial decisions...',
      'src': transactionImage,
      'src_before' : transactionBeforeImage,
      'a': "features/In-depth-Insights",
      'mobile': transactionMobileImage,
      'hover': transactionHoverImage


    },
  ];

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
if(CONFIG['isMobile']){
  return (
    <Row className='home-section-5 m-auto'>
      <h3 className='title'> More of Mafhoom Features </h3>


      {features.map((_, index) => (
        <Row>
        <Col key={_.id} xs={2} md={4} 
          onMouseEnter={() => handleMouseEnter(_.id)}
          onMouseLeave={handleMouseLeave}
          >
          <a href={_.mobile} >
            <img src={hoveredIndex === _.id ? _.mobile : _.mobile} alt="stayAheadImage" className={hoveredIndex === _.id ? 'hoveredFeature' : ''}></img>
          </a>
        </Col>
        <Col key={_.id} xs={10} md={4} 
          onMouseEnter={() => handleMouseEnter(_.id)}
          onMouseLeave={handleMouseLeave}
          >
          <a href={_.a} >
            <h3>{_.title}</h3>
            <p >{_.desc}</p>
          </a>
        </Col>
        </Row>
      ))}
    </Row >
  );
}else{
  return (
    <Row className='home-section-5 m-auto'>
      <h3 className='title'> More of Mafhoom Features </h3>


      {features.map((_, index) => (
        <Col key={_.id} xs={4} md={4} className={CONFIG['isMobile'] ? `border-bottom ${(index+1)%2 === 0 ? ' ' : 'border-end'}` : ''}
          onMouseEnter={() => handleMouseEnter(_.id)}
          onMouseLeave={handleMouseLeave}>
          <a href={_.a} >
            <img src={hoveredIndex === _.id ? _.hover : _.src} alt="stayAheadImage" className='hoveredFeature'></img>
            <h3>{_.title}</h3>
            <p style={{display: hoveredIndex === _.id ? 'block' : 'block' }}>{_.desc}</p>
          </a>
        </Col>
      ))}
    </Row >
  );
}
 
};

export default SectionFive;
