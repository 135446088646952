import {  Container, Nav, NavItem, NavLink, Navbar } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import HomeHeader from '../home/header';
import Popup from './navigation-popup';
import Breadcrumbs from './breadcrumbs';

const Header = (position) => {
  const imageUrl = `${process.env.PUBLIC_URL}/images/logo.svg`;

  const location = useLocation();
  const [isHomePage, setIsHomePage] = useState(location.pathname === '/');
  const [isFeaturePage, setIsFeaturePage] = useState(location.pathname.includes('/features'));
  const [isAboutPage,  setIsAboutPage] = useState(location.pathname.includes('/about-us'));
  const [isNewsPage,  setIsNewsPage] = useState(location.pathname.includes('/news'));
  const [isHelpCenterPage,  setIsHelpCenterPage] = useState(location.pathname.includes('/help-center'));

  position = position.position

  // how can i check if string contain?
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  useEffect(() => {
    const handleRouteChange = () => {
      setIsHomePage(location.pathname === '/');
      setIsFeaturePage(location.pathname.includes('/features'));
      setIsAboutPage(location.pathname.includes('/about-us'));
      setIsNewsPage(location.pathname.includes('/news'));
      setIsHelpCenterPage(location.pathname.includes('/help-center'));
      
    };

    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, [location]);

  return (
    <header className="header" >

      <Nav className="justify-content-end nav">
        <NavItem>
          <NavLink className="active" to="#"><span> Personal Finance </span></NavLink>
        </NavItem>
        
        <NavItem className='business'>
          <NavLink href="https://business.mafhoom.app/"><span> Business </span></NavLink>
        </NavItem>
      </Nav>
      <Navbar className='bg-light-nav' expand="lg" style={{ marginTop: position ? '' : '-4.236vw' }}>
        <Container className='d-flex'>
          <Navbar.Brand href="/">
            <img src={imageUrl} alt="mafhoom logo"></img>
            
          </Navbar.Brand>
          <Navbar id="basic-navbar-nav" style={{margin :'-35px'}}>
            <Nav className="ms-auto">
              <Nav.Link href="/features" 
              onMouseOver={() => setIsDropdownVisible(!isDropdownVisible)} 
              onMouseOut={() => setIsDropdownVisible(!isDropdownVisible)} 
              className={isDropdownVisible || isFeaturePage ? "lined" : ''} >FEATURES</Nav.Link>
              <Nav.Link href="/about-us" className={isAboutPage ? "lined" : ''} >ABOUT</Nav.Link>
              <Nav.Link href="/news" className={isNewsPage ? "lined" : ''}>NEWS</Nav.Link>
              <Nav.Link href="/help-center" className={isHelpCenterPage ? "lined" : ''} >HELP CENTER</Nav.Link>
            </Nav>
          </Navbar>
        </Container>
        {<Popup isDropdownVisible={isDropdownVisible} />}
      </Navbar>
      { !isHomePage && <Breadcrumbs />}
  

      {isHomePage ? <HomeHeader /> : ``}
    </header>

  );
};

export default Header;