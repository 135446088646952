import React from 'react';
import { Col, Row } from 'react-bootstrap';
import CONFIG from '../../config';


const SectionFour = () => {
  const googlePlayImage = `${process.env.PUBLIC_URL}/images/google-play.png`;
  const playStoreImage = `${process.env.PUBLIC_URL}/images/play-store.png`;
  const downloadAppImage = `${process.env.PUBLIC_URL}/images/home-free.png`;

  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const downloadAppMobile = `${process.env.PUBLIC_URL}/images/coines.png`;



  // Windows Phone must come first because its UA also contains "Android"
  if (CONFIG['isMobile']) {
    return (
      <div className="feature home-section-4">
        <div className="feature-download ">
          <Col md={12} sm={12}>
            <img className="download-app-feature" src={downloadAppImage} alt="downloadApp"></img>
            <div className='row m-auto'>

              <p className="download-now-home-p" >Unlock the endless possibilities and join now! <br></br>
                Our app awaits you with a<strong > 100% FREE </strong> experience!
              </p>
              <Col >
            <a href='https://play.google.com/store/apps/details?id=app.mafhoom.mafhoom&pli=1' className='google-play'><img src={googlePlayImage} alt="" ></img></a>
            <a href='https://apps.apple.com/ae/app/mafhoom-budget-bills-deals/id6443959633' className='play-store'><img src={playStoreImage} alt="" ></img></a>

                 </Col>
                    </div>
          </Col>
        </div>
      </div>)
  }   else {
    return (
//       <div className="feature home-section-4">
//         <div className="feature-download ">
//           <Col md={12} sm={12}>
//             <img className="download-app-feature" src={downloadAppImage} alt="downloadApp"></img>
//             <div className='row m-auto'>

//             <p className="download-now-home-p" >Unlock the endless possibilities and join now!
//               Our app awaits you with a<strong > 100% FREE </strong> experience!
//             </p>
//             <a href="https://apps.apple.com/ae/app/mafhoom-budget-bills-deals/id6443959633" style={{ textDecorationLine: 'none', color: '#fff' }}><button className="download-now-home-btn" >Download Now</button></a>
// </div>
//           </Col>
//         </div>
//       </div>
<Row className="about-download2 mx-auto">
<img className="download-app" src={downloadAppImage} alt="downloadApp"></img>

<Col md={9} sm={9}>
                 <div className='row m-auto'>

            <p className="download-now-home-p2" >Unlock the endless possibilities and join now!
               Our app awaits you with a<strong > 100% FREE </strong> experience!
          </p>
           <a href="https://apps.apple.com/ae/app/mafhoom-budget-bills-deals/id6443959633" style={{ textDecorationLine: 'none', color: '#fff' }}><button className="download-now-home-btn2" >Download Now</button></a>
 </div>

</Col>
<Col md={3} sm={3}>
<img className="download-about-mobile2" src={downloadAppMobile} alt="downloadAppMobile"></img>
</Col>
</Row>
      )
  };
};
export default SectionFour;


function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}