import React from 'react';
import { Col, Row } from 'react-bootstrap';

const HomeHeader = () => {
    const mobileImage = `${process.env.PUBLIC_URL}/images/mobile-home.png`;
    const googlePlayImage = `${process.env.PUBLIC_URL}/images/google-play.png`;
    const playStoreImage = `${process.env.PUBLIC_URL}/images/play-store.png`;
  


  return (
    <Row >
       <Col xs={6} md={6}>
        <h2 className="home-header-head"> Transform Your Finances</h2>
        <p className="home-header-text">Gain clarity and take control of your financial journey. Track every aspect of your finances, make smarter decisions, save effectively, and prepare for the future with ease</p>
        <div className='d-flex w-100'>
            <a href='https://play.google.com/store/apps/details?id=app.mafhoom.mafhoom&pli=1' className='google-play'><img src={googlePlayImage} alt="" ></img></a>
            <a href='https://apps.apple.com/ae/app/mafhoom-budget-bills-deals/id6443959633' className='play-store'><img src={playStoreImage} alt="" ></img></a>

        </div>
       </Col>
       <Col xs={6} md={6}>
        <img src={mobileImage} alt="mobileImage" className='header-img-mobile' ></img> 
       </Col>

    </Row>

  );
};

export default HomeHeader;
