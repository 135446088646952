import React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CONFIG from '../../config';
import '../../style/feature.css';
import FeatureDownloadSection from '../feature/feature-download-section';
import FeatureSection from "../feature/feature-section";
import MainFeatureSection from '../feature/main-feature-section';
import FeatureSliderSection from '../feature/feature-slider-section';

const Feature = (featureIndex) => {
  const downloadAppImage = `${CONFIG['BASE_IMAGE_URL']}/download-app.png`;
  const location = useLocation();
  const [isFeaturePage, setIsFeaturePage] = useState(location.pathname === '/features');
  const features = CONFIG['FEATURES'];
 
  useEffect(() => {
    const handleRouteChange = () => {
      setIsFeaturePage(location.pathname === '/features');
    };

    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  });

  const getNextFeatures = ((features, index) => {

    index = index.featureIndex
    if (index === -1) {
      return null;
    }

    const nextFeatures = [];
    for (let i = 1; i <= 3; i++) {
      let element = (index + i) % features.length;
      nextFeatures.push(element);
    }
    return nextFeatures.map(index => features[index]);
  })
  return (
    <div className="feature row">

      { isFeaturePage ? 
      <MainFeatureSection features={features} /> : 
      <>
        <FeatureSection featureIndex={featureIndex.featureIndex} />
        <FeatureSliderSection nextFeatures={getNextFeatures(features,featureIndex )} /> 
      </>}
      <FeatureDownloadSection image={downloadAppImage} />

    </div>

  );
};

export default Feature;
