import React from 'react';
import {  Col, Row } from 'react-bootstrap';
import CONFIG from '../../config';
import { el } from 'date-fns/locale';

const SectionThree = () => {
    const budgetImage = `${process.env.PUBLIC_URL}/images/budget-home.svg`;
    const budgetImagMobile = `${process.env.PUBLIC_URL}/images/home-mobile-33.svg`;

    
    if(CONFIG['isMobile']){


  return (
    <Row className={' home-section-3'} >
        <Col xs={6} md={6}>
            <h4>Unlock Financial Literacy: Learn, Test, and Earn</h4>
            <p>
            Knowledge is the key to financial empowerment.
            </p>  
            <ul>
                <li>Enhance your financial literacy with interactive modules covering various financial topics.</li>
                <li>Learn about budgeting, investing, debt management, and more at your own pace.</li>
                <li>Test your knowledge with quizzes and assessments to reinforce learning.</li>
                <li>Gain coins and XP points based on your learning</li>

            </ul>  
            <a href ="/features/Budget"><button>Learn More</button></a>
        </Col>
        <Col xs={6} md={6}><img src={budgetImagMobile} alt="inDepthImage"></img></Col>

    </Row>
  );
    }else{
      return (
        <Row className={CONFIG['isMobile'] ? ' home-section-3' : 'home-section-1 home-section-3'} >
            <Col xs={6} md={6}><img src={budgetImage} alt="inDepthImage"></img></Col>
            <Col xs={6} md={6}>
                <h4>Unlock Financial Literacy: Learn, Test, and Earn</h4>
                <p>
            Knowledge is the key to financial empowerment.
            </p>  
            <ul>
                <li>Enhance your financial literacy with interactive modules covering various financial topics.</li>
                <li>Learn about budgeting, investing, debt management, and more at your own pace.</li>
                <li>Test your knowledge with quizzes and assessments to reinforce learning.</li>
                <li>Gain coins and XP points based on your learning</li>

            </ul>  
                <button>Learn More</button>
            </Col>
        </Row>
      );
    }
};

export default SectionThree;
 