import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const Breadcrumbs = ({postName}) => {
  const location = useLocation();

  const getBreadcrumbs = () => {
    const pathnames = location.pathname.split('/').filter((x) => x);
    const breadcrumbs = [{
      label: "Home",
      link: `/`,
    }];

    pathnames.forEach((_, index) => {
      const url = `${pathnames.slice(0, index + 1).join('/')}`;
      breadcrumbs.push({
        label: index === pathnames.length - 1 && postName ? postName : getBreadcrumbLabel(_),

        link: url,
      });
    });

    return breadcrumbs;
  };

  const getBreadcrumbLabel = (pathname, index) => {
    return pathname.charAt(0).toUpperCase() + pathname.slice(1); 
    
  };

  const breadcrumbs = getBreadcrumbs();

  return (
    <nav className='nav-breadcrumb' aria-label="breadcrumb">
      <ol className="breadcrumb">
        {breadcrumbs.map((breadcrumb, index) => (
          <li className="" key={index}>
            {breadcrumb.link ? <Link to={breadcrumb.link}>{`  ${breadcrumb.label}  `}</Link> : breadcrumb.label}
            {index < breadcrumbs.length - 1 && <span> &gt;&nbsp; </span>}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
