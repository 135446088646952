import React from 'react';
import { Col, Row } from "react-bootstrap";

const MainFeatureSection = (features) => {

    features = features.features;
    return (

        <Row className="features-cards">
            <Col md={12} sm={12}>
                <h3 >More of Mafhoom’s Features</h3>
            </Col>
            {features.map((_, index) => (
                <Col key={_['feature_id']} md={4} sm={4} className="features-card">
                    <img src={_['feature_icon']} alt="feature_icon"></img>
                    <h3>{_['feature_title']}</h3>
                    <p>
                        {_['feature_description']}
                    </p>
                    <a href={`/features/${_.feature_slug}`}><button>Learn More</button></a>
                </Col>
            ))}
        </Row>
    );
};

export default MainFeatureSection;