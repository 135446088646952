import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import { format } from 'date-fns';
import '../../style/news.css';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const itemsPerPage = 6; // Number of items to display per page

const News = () => {
  const searchIcon = `${process.env.PUBLIC_URL}/images/search-icon.png`;
  const [news, setNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getNews()
      .then(data => {
        setNews(data);
      })
      .catch(error => console.error("Error fetching news:", error));
  }, []);

  const totalPages = Math.ceil(news.length / itemsPerPage);

  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const renderItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, news.length);
    return news.slice(startIndex, endIndex).map((newsItem, index) => {

      return (
        <Col key={index} md={4} sm={4} className='latest-new'>
          <LazyLoadImage src={(newsItem.rttpg_featured_image_url.full[0]).replace("mafhoom.app", "news.mafhoom.app")} alt="latest-new"
              />
         
          <h3>{newsItem.title.rendered}</h3>
          <DateComponent dateString={newsItem.date} />
          <div className="news-content" dangerouslySetInnerHTML={{ __html: newsItem.excerpt.rendered }} />
          <Link to={{
            pathname: `/news/${newsItem.id}`
          }}><button className='news-button'> Read More </button></Link>
        </Col>
      );
    });
  };



  const renderFirstItem = () => {

    return news.map((newsItem, index) => (
      <>
        {index === 0 &&
          <Row >
            <Col md={6} sm={6} className='main-new'>
            <LazyLoadImage src={(newsItem.rttpg_featured_image_url.full[0]).replace("mafhoom.app", "news.mafhoom.app")} alt="New" 
              />
            
            </Col>
            <Col md={6} sm={6} className='main-new-details'>
              <h3>{newsItem.title.rendered}</h3>
              <DateComponent dateString={newsItem.date} />
              <div className="news-content" dangerouslySetInnerHTML={{ __html: newsItem.excerpt.rendered }} />
              <Link to={{
                pathname: `/news/${newsItem.id}`
              }}><button className='main-new-button'> Read More</button></Link>
            </Col>
          </Row>
        }
      </>
    ));

  };

  const DateComponent = ({ dateString }) => {
    const formattedDate = format(new Date(dateString), "do MMMM, yyyy");
    return <span className="news-date">{formattedDate}</span>;
  };

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= Math.min(3, totalPages); i++) {
      pages.push(
        <button key={i} onClick={() => handleClick(i)} disabled={currentPage === i} className='paginate-button'>
          {i}
        </button>
      );
    }
    if (totalPages > 3) {
      pages.push(<span key="dots" className='dots'><span></span><span></span><span></span></span>);
      pages.push(
        <button key={totalPages} onClick={() => handleClick(totalPages)} className='paginate-button' disabled={currentPage === totalPages}>
          {totalPages}
        </button>
      );
    }
    return pages;
  };

  return (
    <div className='news'>
      <Row>
        <Col md={12} sm={12}>
          <h6>Sharing our success with you.</h6>
        </Col>
        <Col md={12} sm={12} className="input-group">
          <div className="form-outline">
            <input type="search" id="form1" className="search-news form-control" placeholder='Search for article...' />
          </div>
          <button type="button" className="btn btn-primary search-icon-news d-flex">
            <img className="m-auto" src={searchIcon} alt="searchIcon" />
          </button>
        </Col>
        <Col md={6} sm={6}>
          {news && Array.isArray(news) ? (
            renderFirstItem()
          ) : (
            <p>Loading...</p>
          )}
        </Col>
      </Row>

      <Row>
        <Col md={12} sm={12} className='latest-news'>
          <h6>Latest News</h6>
        </Col>
      </Row>

      <Row>
        {news && Array.isArray(news) ? (
          <>
            {renderItems()}
            <div className='paginate-div'>
              {renderPagination()}
            </div>
          </>

        ) : (
          <p>Loading...</p>
        )}

      </Row>

    </div>
  );
};

export default News;

export const getNews = async () => {
  try {
    const response = await axios.get(`https://news.mafhoom.app/wp-json/wp/v2/posts`);
    return response.data;
  } catch (error) {
    console.error("Error fetching news:", error);
    throw error;
  }
};
