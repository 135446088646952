import {  Col, Row } from 'react-bootstrap';
import React from 'react';
import CONFIG from '../../config';

const SectionOne = () => {
    const trackImage = `${process.env.PUBLIC_URL}/images/track-spending.svg`;
    const trackImageMobile = `${process.env.PUBLIC_URL}/images/home-mobile-2.svg`;

    
    

    if(CONFIG['isMobile']){

  return (
    <Row className='home-section-1'>
        <Col xs={6} md={6}>
            <h4 className='sub-title'>Expense Management: Track, Categorize, and Control </h4>

            <p>
            Stay Ahead with Your Expenses
            </p>  
            <ul>
                <li>Effortlessly track daily expenditures and categorize them for better understanding.</li>
                <li>Set spending limits and receive alerts when nearing or exceeding your budget.</li>
                <li>Visualize spending patterns through charts and graphs for clearer insights.</li>
            </ul>  
            <button>Learn more</button>
        </Col>
        <Col xs={6} md={6}><img src={trackImageMobile} alt="budgetImage"></img></Col>

    </Row>
  );
}else{
  return (
    <Row className='home-section-1'>
        <Col xs={6} md={6}><img src={trackImage} alt="budgetImage"></img></Col>
        <Col xs={6} md={6}>
            <h4 className='sub-title'>Expense Management: Track, Categorize, and Control </h4>
            <p>
            Stay Ahead with Your Expenses
            </p>  
            <ul>
                <li>Effortlessly track daily expenditures and categorize them for better understanding.</li>
                <li>Set spending limits and receive alerts when nearing or exceeding your budget.</li>
                <li>Visualize spending patterns through charts and graphs for clearer insights.</li>
            </ul>  
            <a href ="/features/Track-Spending"><button>Learn more</button></a>
        </Col>
    </Row>
  );
};
}
export default SectionOne;
 