import { Col, Row } from "react-bootstrap";
import React from 'react';
import CONFIG from '../../config';


const FeatureDownloadSection = (downloadAppImage) => {

    const googlePlayImage = `${process.env.PUBLIC_URL}/images/google-play.png`;
    const playStoreImage = `${process.env.PUBLIC_URL}/images/play-store.png`;
    const downloadAppMobile = `${process.env.PUBLIC_URL}/images/download-app-mobile.png`;


    if(CONFIG['isMobile']){
        return (
            <div className="feature-download">
                <Col md={9} sm={9} className="">
                    <img className="download-app-feature" src={downloadAppImage.image} alt="downloadApp"></img>
                    <p md={3} sm={3} >Transform your <strong>financial future </strong>with ease – download Mafhoom app today and <strong>take control </strong> of your finances effortlessly.</p>
                    <div md={3} sm={3} className="downloads-btn3">
                        <a href="https://play.google.com/store/apps/details?id=app.mafhoom.mafhoom&pli=1"><img src={googlePlayImage} alt="googlePlay"></img></a>
                        <a href='https://apps.apple.com/ae/app/mafhoom-budget-bills-deals/id6443959633'> <img src={playStoreImage} alt="playStore"></img></a>
                    </div>
                </Col>
                {/* <Col md={3} sm={3}>
                    <img className="download-app-mobile-feature" src={downloadAppMobile} alt="downloadAppMobile"></img>
                </Col> */}
            </div>
        );
    }else{
        return (
            <div className=" feature-download m-auto">
                <Col md={9} sm={9} className="">
                    <img className="download-app-feature" src={downloadAppImage.image} alt="downloadApp"></img>
                    <p md={3} sm={3} >Transform your <strong>financial future </strong>with ease – download Mafhoom app today and <strong>take control </strong> of your finances effortlessly.</p>
                    <div md={3} sm={3} className="downloads-btn3">
                        <a href="https://play.google.com/store/apps/details?id=app.mafhoom.mafhoom&pli=1"><img src={googlePlayImage} alt="googlePlay"></img></a>
                        <a href="https://apps.apple.com/ae/app/mafhoom-budget-bills-deals/id6443959633"><img src={playStoreImage} alt="googlePlay"></img></a>
     
                    </div>
                </Col>
                <Col md={3} sm={3}>
                    <img className="download-app-mobile-feature" src={downloadAppMobile} alt="downloadAppMobile"></img>
                </Col>
            </div>
        );
    }
   
};

export default FeatureDownloadSection;