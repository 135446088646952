import React from 'react';
import '../../style/feature.css';
import HelpCenterSection from '../about-us/help-center-section';
 
const HelpCenter = () => {

  return (
    <div >
      <HelpCenterSection />
    </div>
  );
};

export default HelpCenter;
