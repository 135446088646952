import React from 'react';
import SectionOne from '../home/section-1';
import SectionTow from '../home/section-2';
import SectionThree from '../home/section-3';
import SectionFour from '../home/section-4';
import SectionFive from '../home/section-5';
import Journal from '../home/slider-section';


const Home = () => {
  const favIcon = `${process.env.PUBLIC_URL}/images/fav-icon.svg`;
  const likeIcon = `${process.env.PUBLIC_URL}/images/like-icon.svg`;

  const contents = [

    <div key={1} className='feed-back'>
   

      <img src={favIcon} alt="favIcon" className='icon'></img>
      <div>
        <h6>Ali R.</h6>
        <span>Software Developer</span>
        <p>Managing my investments was a daunting task until I discovered Mafhoom. Now, I can confidently track and grow my portfolio.</p>
      </div>

    </div>,

    <div key={1} className='feed-back'>
      <img src={likeIcon} alt="favIcon" className='icon'></img>
      <div>
        <h6>Fatima K.</h6>
        <span>Marketing Manager</span>
        <p>Thanks to Mafhoom, I finally have a clear picture of my finances. Budgeting has never been easier.</p>
      </div>

    </div>,
    <div key={1} className='feed-back'>
      <img src={favIcon} alt="favIcon" className='icon'></img>
      <div>
        <h6>Ahmad R.</h6>
        <span>Freelance Writer</span>
        <p>I learned how to save smarter and achieve my financial goals faster. Mafhoom app is truly a game-changer!</p>
      </div>

    </div>,

    <div key={1} className='feed-back'>
      <img src={likeIcon} alt="favIcon" className='icon'></img>
      <div>
        <h6>Sara M.</h6>
        <span>Small Business Owner</span>
        <p>Mafhoom helped me understand complex financial concepts and make informed decisions. Highly recommended!</p>
      </div>

    </div>,

  ];


  return (
    <div>
      <SectionOne />
      <SectionTow />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <Journal contents={contents} />
    </div>
  );
};

export default Home;
// 260 => 6 => 86.6