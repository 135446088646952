import {  Col, Row } from 'react-bootstrap';
import React from 'react';
import CONFIG from '../../config';

const SectionTow = () => {
    const inDepthImage = `${process.env.PUBLIC_URL}/images/Subscription-Management.svg`;
    const inDepthImageMobile = `${process.env.PUBLIC_URL}/images/Subscription-Management-mobile.svg`;

    

if(CONFIG['isMobile']){
  return (
    <div className='home-section-22'>
    <Row className='home-section-1'>

        <Col xs={6} md={6}>
            <h4 className='sub-title'>Subscription Management: Take Control, Stay Organized, and Save Time </h4>

            <p>
              Knowledge is the key to financial empowerment.
            </p>  
            <ul>
                <li>Enhance your financial literacy with interactive modules covering various financial topics.</li>
                <li>Learn about budgeting, investing, debt management, and more at your own pace.</li>
                <li>Test your knowledge with quizzes and assessments to reinforce learning.</li>
                <li>Gain coins and XP points based on your learning.</li>
            </ul>  
            <a href ="/features/Education"><button>Learn more</button></a>
        </Col>
                    <Col xs={6} md={6}><img src={inDepthImageMobile} alt="budgetImage"></img></Col>

    </Row>
    </div>
  );
}else{
  return (
    <Row className='home-section-1'>
        
        <Col xs={6} md={6}>
            <h4 className='sub-title'>Subscription Management: Take Control, Stay Organized, and Save Time </h4>
            <p>
            Keep track of all your subscriptions and recurring payments effortlessly.
            </p>  
            <ul>
                <li>Easily monitor upcoming expenses in one centralized dashboard.</li>
                <li>Stay organized with reminders for subscription renewals and payments.</li>
                <li>Take control of your subscriptions by never paying for unwanted ones.</li>
            </ul>  
            <button>Learn more</button>
        </Col><Col xs={6} md={6}><img src={inDepthImage} alt="budgetImage"></img></Col>
    </Row>
  );
}
 
};

export default SectionTow;
