import { Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
// import CONFIG from '../../config';
// import runOneSignal from '../../configOneSignal';
import OneSignal from 'react-onesignal';
import CONFIG from '../../config';
import axios from 'axios';

const Footer = () => {
  const imageUrl = `${process.env.PUBLIC_URL}/images/logo-white.svg`;
  const pathImage = `${process.env.PUBLIC_URL}/images/path.svg`;
  const linkedinImage = `${process.env.PUBLIC_URL}/images/linkedin.svg`;
  const facebookImage = `${process.env.PUBLIC_URL}/images/facebook.svg`;
  const twitterImage = `${process.env.PUBLIC_URL}/images/twitter.svg`;
  const instaImage = `${process.env.PUBLIC_URL}/images/insta-icon.svg`;
  const features = CONFIG['FEATURES'];

  const [email, setEmail] = useState('');

  useEffect(() => {
    OneSignal.init({
      appId: CONFIG['ONE_SIGNAL_APP_ID'],
      allowLocalhostAsSecureOrigin: true, // Required for development
    });
  }, []);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
};

const handleSubmit = async (event) => {
  event.preventDefault();

  try {
    const response = await axios.post('https://onesignal.com/api/v1/players', {
      app_id: CONFIG['ONE_SIGNAL_APP_ID'],
      identifier: email,
      device_type: 11, // Set device_type to 11 for email subscriptions
    });

    if (response.status === 200) {
      alert('Subscription successful!');
      setEmail(''); 
    } else {
      alert('Failed to subscribe. Please try again later.');
    }
  } catch (error) {
    console.error('Error subscribing user:', error);
    alert('Failed to subscribe. Please try again later.');
  }
};

  return (
    <footer className="footer">
      <Row className="footer-">
        <Col xs={5} md={2}>
          <h3 className="footer-heading"><img src={imageUrl} alt='mafhoom logo' style={{width: 'inherit'}}></img></h3>
          <label className="subscribe-label" >Subscribe</label>
          <div className="subscribe">
            <input className="form-control" placeholder="Email address" 
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              required/>
            <img className="fill" src={pathImage} alt="path" onClick={handleSubmit}></img>
          </div>
          <p className="footer-text">Hello, we are at Mafhoom. trying to make an effort to help you organize your finace</p>
        </Col>
        <Col className="first-footer-col footer-col">
          <h3 className="footer-heading">About</h3>
          <ul className="list-unstyled footer-list">
            <li><a href="/about-us">About Mafhoom</a></li>
            <li><a href="/news">Company Updates</a></li>
            <li><a href="/help-center">Contact Us</a></li>
            <li><a href="https://mafhoom.freshdesk.com/support/home">Support</a></li>
          </ul>
        </Col>
        <Col className="footer-col">
          <h3 className="footer-heading">Feature</h3>
          <ul className="list-unstyled footer-list" style={{columnCount: 2}}>
            {features.map((_, index) => (
              <li><a href={`/features/${_.feature_slug}`}>{_.feature_title}</a></li>
            ))}
          </ul>
        </Col>
        <Col className="footer-col">
          <h3 className="footer-heading">Others</h3>
          <ul className="list-unstyled footer-list">
            {/* <li><a href="#">Safety & Security</a></li> */}
            <li><a href="/terms-of-services">Terms of Services</a></li>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
          </ul>
        </Col>
      </Row>
      <Row>
      <div className="flex flex-social-dev" >
          <a target='_blank' href="https://www.linkedin.com/company/mafhoomapp/" ><img src={linkedinImage} className="social" alt="linkedIn"></img></a>
          <a target='_blank' href="https://web.facebook.com/mafhoomapp" ><img src={facebookImage} className="social" alt="facebook"></img></a>
          <a target='_blank' href="https://twitter.com/mafhoomapp"><img src={twitterImage} className="social" alt="twitter"></img></a>
          <a target='_blank' href="https://www.instagram.com/mafhoomapp"><img src={instaImage} className="social" alt="instaImage"></img></a>
        </div>
      </Row>


    </footer>
  );
};

export default Footer;
