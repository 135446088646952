import React from 'react';
import '../../style/about-us.css';
import { Col, Row } from "react-bootstrap";
import CONFIG from '../../config';


const
    AboutUsSection = (downloadAppImage) => {
        const whoAreWeImage = `${process.env.PUBLIC_URL}/images/who-are-we.png`;
        const ourValuesImage = `${process.env.PUBLIC_URL}/images/our-values.png`;
        const googlePlayImage = `${process.env.PUBLIC_URL}/images/google-play.png`;
        const playStoreImage = `${process.env.PUBLIC_URL}/images/play-store.png`;
        const numberOneIcon = `${process.env.PUBLIC_URL}/images/1.png`;
        const numberTwoIcon = `${process.env.PUBLIC_URL}/images/2.png`;
        const numberThreeIcon = `${process.env.PUBLIC_URL}/images/3.png`;
        const numberFourIcon = `${process.env.PUBLIC_URL}/images/4.png`;
        const ziadFounder = `${process.env.PUBLIC_URL}/images/founders/ziad.png`;
        const tarikFounder = `${process.env.PUBLIC_URL}/images/founders/tarek.png`;
        const ahmadFounder = `${process.env.PUBLIC_URL}/images/founders/ahmed.png`;
        const linkedinImage = `${process.env.PUBLIC_URL}/images/linkedin-white.svg`;
        const youtubeImage = `${process.env.PUBLIC_URL}/images/youtube-white.png`;
        const twitterImage = `${process.env.PUBLIC_URL}/images/twitter-white.png`;
        const webImage = `${process.env.PUBLIC_URL}/images/web-white.png`;
        const iconImage = `${process.env.PUBLIC_URL}/images/icon-white.png`;
        const handIcon = `${process.env.PUBLIC_URL}/images/hand.png`;
        const successIcon = `${process.env.PUBLIC_URL}/images/fin-success.svg`;
        const communityIcon = `${process.env.PUBLIC_URL}/images/community.svg`;
        const trustIcon = `${process.env.PUBLIC_URL}/images/trust.svg`;
        const backgroundImage = `${process.env.PUBLIC_URL}/images/choosen-mafhoom.png`;
        const downloadAppMobile = `${process.env.PUBLIC_URL}/images/download-about-mobile.png`;


if(CONFIG['isMobile']){
    return (
        <div >
            <Row className="who-are-we">
                <Col md={6} sm={6} style={{ marginTop: '20px', marginBottom: '83px' }}>
                    <h3> Who We Are</h3>
                    <div>
                        <p>
                            Welcome to Mafhoom, the ultimate personal finance app designed to simplify and enhance your financial life.
                        </p>
                        <p>
                            Available on both the App Store and Google Play Store, Mafhoom is here to be your reliable partner on your journey to financial success.
                        </p>
                    </div>
                </Col>
                <Col md={6} sm={6} className='who-are-we-img'>
                    <img src={whoAreWeImage} alt="whoAreWe"></img>
                </Col>
            </Row>
            <Row className='our-values'>
                <Col md={12} sm={12} className=''>

                    <h3> Our Values</h3>
                </Col>
                <Col md={6} sm={6} className='our-values-img'>
                    <img src={ourValuesImage} alt="ourValuesImage"></img>
                </Col>
                <Col md={6} sm={6}>
                    <div>

                        <img src={numberOneIcon} alt="numberIcon"></img>

                        <p><strong>Integrity:</strong> <br />
                            We uphold the highest ethical standards in everything we do, ensuring transparency and honesty in all our interactions.                            </p>
                    </div>
                    <div>
                        <img src={numberTwoIcon} alt="numberIcon"></img>

                        <p>
                            <strong>Empowerment:</strong> <br />
                            We believe in empowering individuals to take control of their financial futures, providing them with the tools and knowledge they need to make informed decisions.                            </p>
                    </div>
                    <div>
                        <img src={numberThreeIcon} alt="numberIcon"></img>
                        <p>
                            <strong>Innovation:</strong> <br />
                            We continuously strive to innovate and improve, leveraging cutting-edge technology to deliver new and improved solutions that meet the evolving needs of our users.                            </p>
                    </div>
                    <div>
                        <img src={numberFourIcon} alt="numberIcon"></img>
                        <p>
                            <strong>Accessibility:</strong> <br />
                            We are committed to making financial services accessible to all, regardless of background or circumstance, ensuring that everyone has the opportunity to achieve financial well-being.                            </p>

                    </div>
                </Col>
            </Row>


            <Row className='choose-mafhoom '>

                {/* <img src={backgroundImage} className="choose-mafhoom-img" alt="peopleSays" /> */}

                <Col md={12} sm={12} className='choose-mafhoom-head'>
                    <h3> Why Choose Mafhoom?</h3>
                </Col>
                <Col md={6} sm={6} className='choose-mafhoom-div'>
                    <div>
                        <img src={handIcon} alt="handIcon"></img></div>
                    <div ><h3> Empowering Your Financial Journey </h3>
                        <p>
                            At Mafhoom, we understand the everyday challenges of managing finances. That's why we've developed a range of powerful features to help you budget effectively, spend wisely, and save effortlessly. With Mafhoom by your side, you can take control of your finances with confidence.
                        </p>
                    </div>
                </Col>
                <Col md={6} sm={6} className='choose-mafhoom-div'>
                    <div>
                        <img src={successIcon} alt="successIcon"></img></div>
                    <div ><h3> Your Partner in Financial Success </h3>
                        <p>With Mafhoom, achieving your financial goals has never been easier. Whether you're saving for a rainy day, planning for the future, or simply looking to make smarter financial decisions, Mafhoom has the tools and resources you need to succeed.

                        </p>
                    </div>
                </Col>
                <Col md={6} sm={6} className='choose-mafhoom-div'>
                    <div>
                        <img src={trustIcon} alt="trustIcon"></img></div>
                    <div ><h3> Expertise You Can Trust </h3>
                        <p>Our team at Mafhoom brings together a wealth of experience in financial services, with a combined leadership experience of over 50 years. We've seen the ups and downs of the financial world, and we're here to share our expertise with you. Rest assured, you're in good hands with Mafhoom.</p>
                    </div>
                </Col>
                <Col md={6} sm={6} className='choose-mafhoom-div'>
                    <div>
                        <img src={communityIcon} alt="communityIcon"></img></div>
                    <div ><h3> Join the Mafhoom Community Today </h3>
                        <p>Experience the difference with Mafhoom and take the first step towards financial peace of mind. Download the app now and start your journey to a brighter financial future with Mafhoom. </p>
                    </div>
                </Col>
            </Row>


            <Row className='founders'>

                <h3> Founding Team</h3>
                <Row>
                    <Col md={4} sm={4} className='founders-cards'>
                        <img src={ziadFounder} alt="ziadFounder"></img>
                        <div >
                            <p className='name'> Ziad Melhem </p>
                            <p className='position'> CHIEF BUSINESS OFFICER </p>
                            <p className='desc'> A seasoned business professional with extensive industry knowledge. He has a passion for innovation leading the charge in revolutionizing the financial sector.</p>
                            <div className="social-div">
                                <a target='_blank' href="https://www.linkedin.com/in/mziad/" ><img src={linkedinImage} className="social" alt="linkedIn"></img></a>
                                <a target='_blank' href="https://twitter.com/zmelhem" ><img src={twitterImage} className="social" alt="twitter"></img></a>
                                <a target='_blank' href="https://www.youtube.com/c/ziadmelhem" ><img src={youtubeImage} className="social" alt="youtubeImage"></img></a>
                                <a target='_blank' href="https://www.ziadmelhem.com/" ><img src={webImage} className="social" alt="webImage"></img></a>

                            </div>
                        </div>
                    </Col>
                    <Col md={4} sm={4} className='founders-cards'>
                        <img src={ahmadFounder} alt="ahmadFounder"></img>
                        <div>
                            <p className='name'> Ahmad Khatib </p>
                            <p className='position'> CHIEF EXECUTIVE OFFICER </p>
                            <p className='desc'> A seasoned finance expert, brings extensive experience across diverse sectors. He serves as the co-founder of Mafhoom Technologies with a vision to transform financial landscape. </p>
                            <div className="social-div d-flex mt-0">
                                <a target='_blank' href="https://www.linkedin.com/in/ahmadkhatib/" ><img src={linkedinImage} className="social" alt="linkedIn"></img></a>
                                <a target='_blank' href="https://twitter.com/ahmad_khatib" ><img src={twitterImage} className="social" alt="twitter"></img></a>
                                <a target='_blank' href="https://www.youtube.com/c/ahmadkhatibchannel" ><img src={youtubeImage} className="social" alt="youtubeImage"></img></a>
                                <a target='_blank' href="https://ahmadkhatib.buzzsprout.com/" ><img src={iconImage} className="social" alt="iconImage"></img></a>

                            </div>
                        </div>
                    </Col>

                    <Col md={4} sm={4} className='founders-cards'>
                        <img src={tarikFounder} alt="tarikFounder"></img>
                        <div>
                            <p className='name'> Tarek Abdelaziz </p>
                            <p className='position'> CHIEF TECHNOLOGY OFFICER </p>
                            <p className='desc'> A tech savvy visionary. With his expertise, we're revolutionizing the financial world with cutting-edge solutions. </p>
                            <div className="social-div">
                                <a target='_blank' href="https://www.linkedin.com/in/tarek-abdelaziz-6b3a2512/" ><img src={linkedinImage} className="social" alt="linkedIn"></img></a>
                                <a target='_blank' href="https://twitter.com/tarekaa" ><img src={twitterImage} className="social" alt="twitter"></img></a>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Row>
                        
            <Row className="about-download mx-auto">
                    <img className="download-app" src={downloadAppImage.image} alt="downloadApp"></img>

                    <Col md={9} sm={9} className='download-about-btn-3'>
                        <p md={3} sm={3}>Transform your <strong>financial future </strong>with ease – download Mafhoom app today and <strong>take control </strong> of your finances effortlessly.</p>
                        <div md={3} sm={3} className="downloads-btn-2">
                            <a href="https://play.google.com/store/apps/details?id=app.mafhoom.mafhoom&pli=1"><img src={googlePlayImage} alt="googlePlay"></img></a>
                            <a href='https://apps.apple.com/ae/app/mafhoom-budget-bills-deals/id6443959633'><img src={playStoreImage} alt="playStore"></img></a>
                        </div>

                    </Col>
                    { !CONFIG['isMobile'] ? 
                    <Col md={3} sm={3}>
                    <img className="download-about-mobile" src={downloadAppMobile} alt="downloadAppMobile"></img>
                    </Col> : ''}
                </Row>
        </div>

    );
}else{
 return (
            <div className='about-us-style' >
                <Row className="who-are-we">
                    <Col md={6} sm={6} style={{ marginTop: '20px', marginBottom: '83px' }}>
                        <h3> Who We Are</h3>
                        <div>
                            <p>
                                Welcome to Mafhoom, the ultimate personal finance app designed to simplify and enhance your financial life.
                            </p>
                            <p>
                                Available on both the App Store and Google Play Store, Mafhoom is here to be your reliable partner on your journey to financial success.
                            </p>
                        </div>
                    </Col>
                    <Col md={6} sm={6} className='who-are-we-img'>
                        <img src={whoAreWeImage} alt="whoAreWe"></img>
                    </Col>
                </Row>
                <Row className='our-values'>
                    <Col md={12} sm={12} className=''>

                        <h3> Our Values</h3>
                    </Col>
                    <Col md={6} sm={6} className='our-values-img'>
                        <img src={ourValuesImage} alt="ourValuesImage"></img>
                    </Col>
                    <Col md={6} sm={6}>
                        <div>

                            <img src={numberOneIcon} alt="numberIcon"></img>

                            <p><strong>Integrity:</strong> <br />
                                We uphold the highest ethical standards in everything we do, ensuring transparency and honesty in all our interactions.                            </p>
                        </div>
                        <div>
                            <img src={numberTwoIcon} alt="numberIcon"></img>

                            <p>
                                <strong>Empowerment:</strong> <br />
                                We believe in empowering individuals to take control of their financial futures, providing them with the tools and knowledge they need to make informed decisions.                            </p>
                        </div>
                        <div>
                            <img src={numberThreeIcon} alt="numberIcon"></img>
                            <p>
                                <strong>Innovation:</strong> <br />
                                We continuously strive to innovate and improve, leveraging cutting-edge technology to deliver new and improved solutions that meet the evolving needs of our users.                            </p>
                        </div>
                        <div>
                            <img src={numberFourIcon} alt="numberIcon"></img>
                            <p>
                                <strong>Accessibility:</strong> <br />
                                We are committed to making financial services accessible to all, regardless of background or circumstance, ensuring that everyone has the opportunity to achieve financial well-being.                            </p>

                        </div>
                    </Col>
                </Row>


                <Row className='choose-mafhoom '>

                    <img src={backgroundImage} className="choose-mafhoom-img" alt="peopleSays" />

                    <Col md={12} sm={12} className='choose-mafhoom-head'>
                        <h3> Why Choose Mafhoom?</h3>
                    </Col>
                    <Col md={6} sm={6} className='choose-mafhoom-div'>
                        <div>
                            <img src={handIcon} alt="handIcon"></img></div>
                        <div ><h3> Empowering Your Financial Journey </h3>
                            <p>
                                At Mafhoom, we understand the everyday challenges of managing finances. That's why we've developed a range of powerful features to help you budget effectively, spend wisely, and save effortlessly. With Mafhoom by your side, you can take control of your finances with confidence.
                            </p>
                        </div>
                    </Col>
                    <Col md={6} sm={6} className='choose-mafhoom-div'>
                        <div>
                            <img src={successIcon} alt="successIcon"></img></div>
                        <div ><h3> Your Partner in Financial Success </h3>
                            <p>With Mafhoom, achieving your financial goals has never been easier. Whether you're saving for a rainy day, planning for the future, or simply looking to make smarter financial decisions, Mafhoom has the tools and resources you need to succeed.

                            </p>
                        </div>
                    </Col>
                    <Col md={6} sm={6} className='choose-mafhoom-div'>
                        <div>
                            <img src={trustIcon} alt="trustIcon"></img></div>
                        <div ><h3> Expertise You Can Trust </h3>
                            <p>Our team at Mafhoom brings together a wealth of experience in financial services, with a combined leadership experience of over 50 years. We've seen the ups and downs of the financial world, and we're here to share our expertise with you. Rest assured, you're in good hands with Mafhoom.</p>
                        </div>
                    </Col>
                    <Col md={6} sm={6} className='choose-mafhoom-div'>
                        <div>
                            <img src={communityIcon} alt="communityIcon"></img></div>
                        <div ><h3> Join the Mafhoom Community Today </h3>
                            <p>Experience the difference with Mafhoom and take the first step towards financial peace of mind. Download the app now and start your journey to a brighter financial future with Mafhoom. </p>
                        </div>
                    </Col>
                </Row>


                <Row className='founders'>

                    <h3> Founding Team</h3>
                    <Row>
                        <Col md={4} sm={4} className='founders-cards'>
                            <img src={ziadFounder} alt="ziadFounder"></img>
                            <div >
                                <p className='name'> Ziad Melhem </p>
                                <p className='position'> CHIEF BUSINESS OFFICER </p>
                                <p className='desc'> A seasoned business professional with extensive industry knowledge. He has a passion for innovation leading the charge in revolutionizing the financial sector.</p>
                                <div className="social-div">
                                    <a target='_blank' href="https://www.linkedin.com/in/mziad/" ><img src={linkedinImage} className="social" alt="linkedIn"></img></a>
                                    <a target='_blank' href="https://twitter.com/zmelhem" ><img src={twitterImage} className="social" alt="twitter"></img></a>
                                    <a target='_blank' href="https://www.youtube.com/c/ziadmelhem" ><img src={youtubeImage} className="social" alt="youtubeImage"></img></a>
                                    <a target='_blank' href="https://www.ziadmelhem.com/" ><img src={webImage} className="social" alt="webImage"></img></a>

                                </div>
                            </div>
                        </Col>
                        <Col md={4} sm={4} className='founders-cards'>
                            <img src={ahmadFounder} alt="ahmadFounder"></img>
                            <div>
                                <p className='name'> Ahmad Khatib </p>
                                <p className='position'> CHIEF EXECUTIVE OFFICER </p>
                                <p className='desc'> A seasoned finance expert, brings extensive experience across diverse sectors. He serves as the co-founder of Mafhoom Technologies with a vision to transform financial landscape. </p>
                                <div className="social-div d-flex mt-0">
                                    <a target='_blank' href="https://www.linkedin.com/in/ahmadkhatib/" ><img src={linkedinImage} className="social" alt="linkedIn"></img></a>
                                    <a target='_blank' href="https://twitter.com/ahmad_khatib" ><img src={twitterImage} className="social" alt="twitter"></img></a>
                                    <a target='_blank' href="https://www.youtube.com/c/ahmadkhatibchannel" ><img src={youtubeImage} className="social" alt="youtubeImage"></img></a>
                                    <a target='_blank' href="https://ahmadkhatib.buzzsprout.com/" ><img src={iconImage} className="social" alt="iconImage"></img></a>

                                </div>
                            </div>
                        </Col>

                        <Col md={4} sm={4} className='founders-cards'>
                            <img src={tarikFounder} alt="tarikFounder"></img>
                            <div>
                                <p className='name'> Tarek Abdelaziz </p>
                                <p className='position'> CHIEF TECHNOLOGY OFFICER </p>
                                <p className='desc'> A tech savvy visionary. With his expertise, we're revolutionizing the financial world with cutting-edge solutions. </p>
                                <div className="social-div">
                                    <a target='_blank' href="https://www.linkedin.com/in/tarek-abdelaziz-6b3a2512/" ><img src={linkedinImage} className="social" alt="linkedIn"></img></a>
                                    <a target='_blank' href="https://twitter.com/tarekaa" ><img src={twitterImage} className="social" alt="twitter"></img></a>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Row>
                            
                <Row className="about-download mx-auto">
                    <img className="download-app" src={downloadAppImage.image} alt="downloadApp"></img>

                    <Col md={9} sm={9}>
                        <p md={3} sm={3}>Transform your <strong>financial future </strong>with ease – download Mafhoom app today and <strong>take control </strong> of your finances effortlessly.</p>
                        <div md={3} sm={3} className="downloads-btn-2">
                            <a href="https://play.google.com/store/apps/details?id=app.mafhoom.mafhoom&pli=1"><img src={googlePlayImage} alt="googlePlay"></img></a>
                            <a href='https://apps.apple.com/ae/app/mafhoom-budget-bills-deals/id6443959633'><img src={playStoreImage} alt="playStore"></img></a>
                        </div>

                    </Col>
                    <Col md={3} sm={3}>
                    <img className="download-about-mobile" src={downloadAppMobile} alt="downloadAppMobile"></img>
                    </Col>
                </Row>
            </div>

        );
}

       
    };

export default AboutUsSection;