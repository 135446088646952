import React, { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";



const Journal = (contents) => {
  contents = contents.contents;
  const peopleSaysImage = `${process.env.PUBLIC_URL}/images/people-says.png`;


  return (
    // <Container  className="slider-container">
    <div className="d-flex justify-content-center  my-5 slider-container">
      <h3 className="slider-container-title">What People Say About Us</h3>
      <Draggable>
        {contents.map((_, index) => (
          <Col className="flex flex-col" key={index}>
            {_}
          </Col>
        ))}

      </Draggable>
      <div className="dots-container">
      {Array.from({ length: Math.ceil(contents.length/2) }).map((_, index) => (
          <span key={index} className="dot"></span>
        ))}
      </div>

      <img src={peopleSaysImage} className="slider-container-img" alt="peopleSays" />

    </div>
    // </Container>
  );
};

const Draggable = ({ children }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const contentWidth = useRef(0);
  const updateDots = () => {

    const contentVisibleWidth = 1614 / 3;

    const dots = document.querySelectorAll(".dot");
    const visibleArea = contentVisibleWidth;
    for (let i = 0; i < dots.length ; i++) {
      const dotLeft = i * visibleArea;

      if (scrollPosition >= dotLeft && scrollPosition < dotLeft + visibleArea) {

        dots[i].classList.add("active");
      } else {
        
        dots[i].classList.remove("active");
      }
    }
  };
  const ourRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0,
  });

  const handleDragStart = (e) => {
    if (!ourRef.current) {
      console.error("ourRef.current is null in handleDragStart");
      return;
    }

    // if (!ourRef.current) return;

    const slider = ourRef.current;
    const startX = e.pageX - slider.offsetLeft;
    const startY = e.pageY - slider.offsetTop;
    const scrollLeft = slider.scrollLeft;
    const scrollTop = slider.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
    setIsMouseDown(true);
    document.body.style.cursor = "grabbing";
  };
  React.useEffect(() => {
    const content = ourRef.current.querySelectorAll(".flex-col");
    if (Array.isArray(content)) {
      contentWidth.current = content.reduce((sum, el) => sum + el.offsetWidth, 0);
    }
  }, []);
  const handleDragEnd = () => {
    if (!ourRef.current) {
      return;
    }


    setIsMouseDown(false);
    document.body.style.cursor = "default";
  };

  const handleDrag = (e) => {

    if (!isMouseDown || !ourRef.current) {
      return;
    }
    e.preventDefault();

    const slider = ourRef.current;
    const x = e.pageX - slider.offsetLeft;
    const y = e.pageY - slider.offsetTop;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    const walkY = (y - mouseCoords.current.startY) * 1.5;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    slider.scrollTop = mouseCoords.current.scrollTop - walkY;
  };

  const handleScroll = (e) => {
    setScrollPosition(e.target.scrollLeft);
    updateDots();
  };


  return (
    <div
      ref={ourRef}
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onMouseMove={handleDrag}
      onScroll={handleScroll}
      className="flex-nowrap overflow-x-auto row">
      {children}
    </div>
  );
};

export default Journal;
