import React, { useEffect, useState } from 'react';
import Header from './header';
import MobileHeader from './mobile-header';
import Footer from './footer';
import MobileFooter from './mobile-footer';

const Layout = ({  children  }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Adjust breakpoint as needed
  const [marginTop, setMarginTop] = useState(100); // Initial top margin
  const arrowIcon = `${process.env.PUBLIC_URL}/images/top-arrow.svg`;

  useEffect(() => {

    function handleScroll() {

      if (window.scrollY > 0) {

        setMarginTop(0); 

      } else {

        setMarginTop(100);

      }

    }

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);

  }, []); 

    
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' }); 
  }

  
  return (
    <div className="layout overflow-x-hidden">
        { !marginTop ? <img onClick={scrollToTop} alt="arrowIcon" src={arrowIcon} 
            style={ { position: 'fixed', right: '10.417vw' , bottom: '3.472vw', cursor:'pointer', zIndex:3 }}></img>
        : '' }
<main>
      {isMobile ? <MobileHeader position={marginTop} /> : <Header position={marginTop} />}
      {children}

      {isMobile ? <MobileFooter /> : <Footer />}</main>

    </div>
  );
};

export default Layout;