import React, { useState } from 'react';
import '../../style/help-center.css';
import { Col, Row } from "react-bootstrap";
import axios from 'axios';
import CONFIG from '../../config';


const HelpCenterSection = () => {
    const helpCenterImage = `${process.env.PUBLIC_URL}/images/help-center.png`;
    const mapImage = `${CONFIG['BASE_IMAGE_URL']}/map.png`;
    const locationImage = `${process.env.PUBLIC_URL}/images/location.png`;
    const calenderImage = `${process.env.PUBLIC_URL}/images/calender.png`;
    const timeImage = `${process.env.PUBLIC_URL}/images/time.png`;
    const emailImage = `${process.env.PUBLIC_URL}/images/email.png`;

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const handleMobileChange = (event) => {
        setMobile(event.target.value);
    };
    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };
    const handleSubjectChange = (event) => {
        setSubject(event.target.value);
    };




    const handleSubmit = async (event) => {
        // event.preventDefault();

        try {
            const url = `https://dev.mafhoom.app/api/v1/web/contact_us?name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}&phone=${encodeURIComponent(mobile)}&subject=${encodeURIComponent(subject)}&description=${encodeURIComponent(message)}`;
            const response = await axios.post(url);

            console.log(response);
            console.log('Form submitted successfully:', response.data);

            // Reset form state
            setEmail('');
            setName('');
            setMobile('');
            setMessage('');
            setSubject('');
        } catch (error) {
            console.error('Error submitting form:', error);
            // Optionally display an error message to the user
        }
    };



return (
    <div className='help-center-section'>
        <Row className="help-center ">
            <Col md={6} sm={6} className='first-help-center'>
                <h3 > Do you have a question in the app?</h3>
                <div>
                    <p>
                    Find answers quickly in our FAQs section. Whether it's about using features or troubleshooting, we've got you covered.
                    </p>
                    <a href="https://mafhoom.freshdesk.com/support/solutions/151000038966" style={{textDecorationLine:'none' , color:'#fff' }}><button> Go to FAQs </button></a>
                </div>

                <h3> Do you have an issue in the app?</h3>
                <div>
                    <p className="paragraph">
                    Our dedicated Customer Support team is here to help. Submit a support ticket detailing your concern, and we'll address it promptly.
                    </p>
                    <a href="https://mafhoom.freshdesk.com/support/tickets/new" style={{textDecorationLine:'none' , color:'#fff' }}><button> Submit a Ticket </button></a>
                </div>
            </Col>
            <Col md={6} sm={6} className='help-center-img'>
                <img className='' src={helpCenterImage} alt="helpCenter"></img>
            </Col>
        </Row>

        <Row className="help-center">
            <Col md={6} sm={6}>
                
            </Col>
        </Row>

        <Row className="help-center">
            <Col md={6} sm={6} className='help-center-md-6'>
                <h3> Interested in partnering with Mafhoom?</h3>
                <div>
                    <p className="paragraph">
                    Let's explore collaboration opportunities! Reach out to us to discuss potential partnerships and initiatives. Submit your proposal through the support ticket form, and we'll connect with you soon.
                    </p>
                    <a href="#" style={{textDecorationLine:'none' , color:'#fff' }}><button> Go to business </button></a>
                </div>
            </Col>
        </Row>

        <Row className='map'>
            <h3> Contact Us </h3>
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col md={6} sm={6} className='map-form'>
                        <Row>
                            <Col md={5}>
                                <label>Your Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    value={name}
                                    onChange={handleNameChange}
                                    required
                                />
                            </Col>
                            <Col md={5}>
                                <label>Your Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={5}>
                                <label>Subject</label>
                                <input
                                    type="subject"
                                    id="subject"
                                    value={subject}
                                    onChange={handleSubjectChange}
                                    required
                                />
                            </Col>
                            <Col md={5}>
                                <label>Your Phone Number</label>
                                <input
                                    type="mobile"
                                    id="mobile"
                                    value={mobile}
                                    onChange={handleMobileChange}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <label>Message</label>
                            <textarea id="message"
                                value={message}
                                onChange={handleMessageChange}
                                required></textarea>
                        </Row>
                        <Row><button className='form-btn'>Submit</button></Row>
                    </Col>
                    
                    <Col md={6} sm={6} className='map-img'>
                    <a href='https://maps.app.goo.gl/jVeCccKBf663sBnr5'>
                        <img src={mapImage} alt="map"></img>
                        </a>
                    </Col>
                </Row>
            </form>
            <div className='mafhoom-info'>
            <p >
                <div><img alt="locationImage" src={locationImage}></img></div>
                <div><span> Mafhoom Technologies Ltd. DIFC Innovation Hub, Level 1, Zone D.Dubai – UAE</span></div>
            </p>
            <p >
                <div><img alt="emailImage" src={emailImage}></img></div>
                <div><span> hello@mafhoom.app </span></div>
            </p>
            <p >
                <div><img alt="calenderImage" src={calenderImage}></img></div>
                <div><span> Sunday - Thursday</span></div>
            </p>
            <p >
                <div><img alt="" src={timeImage}></img></div>
                <div><span> 8:00 - 17:00 (GMT+4)</span></div>
            </p>
        </div>
        </Row>

       
    </div >

);
};

export default HelpCenterSection;