import './App.css';
import './../src/style/home.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/layouts/layout';
import Home from './components/pages/home';
import Feature from './components/pages/feature';
import AboutUs from './components/pages/about-us';
import HelpCenter from './components/pages/help-center';
import News from './components/pages/news';
import CONFIG from './config';
import NewSection from './components/news/news-section';
import Privacy from './components/pages/privacy';
import Terms from './components/pages/terms';

const App = () => {
    const features = CONFIG['FEATURES'];

    return (

        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/features" element={<Feature />} />
                    {features.map((_, index) => (
                        <Route key={index} path={`/features/${_.feature_slug}`} element={<Feature featureIndex={index} />} />
                    ))}
                    <Route path="/news" element={<News />} />
                    <Route path="/news/:id" element={<NewSection />} />

                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/help-center" element={<HelpCenter />} />
                    <Route path="/terms-of-services" element={<Terms />} />
                    <Route path="/privacy-policy" element={<Privacy />} />

                </Routes>

            </Layout>

        </Router>
    );
};

export default App;
