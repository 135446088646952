import React from 'react';
import { Col, Row } from "react-bootstrap";
import CONFIG from '../../config';


const FeatureSliderSection = (nextFeatures) => {
    const features = nextFeatures.nextFeatures;
    if(CONFIG['isMobile']){
        return (
            <Row className="feature-slider m-auto">
                <Col md={12} sm={12}>
                    <h5> More of Mafhoom’s Features: </h5>
                </Col>
                {features.map((_, index) => (
                    <a className="a-feature-main" href={`/features/${_.feature_slug}`} >
                    <Col key={index} md={4} sm={4} className="next-feature-card" >
                        <img src={_.popup_feature_icon} alt='feature_icon'></img>
                        <div  className='feature-slider-feedback' >
                            <h5>{_.feature_title}</h5>
                            <p className="p-feature" >{_.feature_description}</p>
                            <a className="a-feature" href={`/features/${_.feature_slug}`} >Learn More</a>
                        </div>
                    </Col>
                    </a>
                ))}

            </Row>
    );
    }else{
        return (
            <Row className="feature-slider m-auto">
                <Col md={12} sm={12}>
                    <h5> More of Mafhoom’s Features: </h5>
                </Col>
                {features.map((_, index) => (
                    <Col key={index} md={4} sm={4} className="next-feature-card">
                        <img src={_.popup_feature_icon} alt='feature_icon'></img>
                        <div className='feature-slider-feedback'>
                            <h5>{_.feature_title}</h5>
                            <p className="p-feature" >{_.feature_description}</p>
                            <a className="a-feature" href={`/features/${_.feature_slug}`} >Learn More</a>
                        </div>
                    </Col>
                ))}

            </Row>
    );
    }
   
};

export default FeatureSliderSection;